export const config = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  skin: process.env.NEXT_PUBLIC_APP_SKIN,
  trademarkApiUrl: process.env.NEXT_PUBLIC_TRADEMARK_API_URL,
  email: process.env.NEXT_PUBLIC_APP_EMAIL,
  membership: process.env.NEXT_PUBLIC_MEMBERSHIP_URL,
  phone: process.env.NEXT_PUBLIC_APP_PHONE,
  basePath: process.env.NEXT_PUBLIC_BASE_PATH,
  gtmId: process.env.NEXT_PUBLIC_APP_GTM,
  gaId: process.env.NEXT_PUBLIC_APP_GA,
};
